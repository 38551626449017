<template>
  <a-card class="card" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getCumsMetaPage"
      :deleteFun="deleteCumsMetaInfo"
      :createFun="createCumsMetaInfo"
      :updateFun="updateCumsMetaInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getCommonOptions, getCustomer } from '@/api/common'
import { getCumsMetaPage, createCumsMetaInfo, updateCumsMetaInfo, deleteCumsMetaInfo, getCumsMetaOption } from '@/api/cums'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        {
          label: '费用名称', name: 'fee_id', fieldType: 'select', queryType: '', options: []
        }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '起运地',
          dataIndex: 'departure_name'
        },
        {
          title: '目的地',
          dataIndex: 'destination_name'
        },
        {
          title: '箱型',
          dataIndex: 'box_name'
        },
        {
          title: '港区',
          dataIndex: 'port_name'
        },
        {
          title: '费用名称',
          dataIndex: 'fee_name'
        },
        {
          title: '固定金额',
          dataIndex: 'amount'
        },
        {
          title: '客户名称',
          dataIndex: 'customer_name'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        departure: [
          { required: true, message: '请选择起运地', trigger: 'change' }
        ]
      },
      modelTitle: '固定费用',
      formFields: [
        {
          label: '起运地', name: 'departure', type: 'select', options: []
        },
        {
          label: '目的地', name: 'destination', type: 'select', options: []
        },
        {
          label: '箱型', name: 'box_id', type: 'select', func: getCommonOptions, params: 'box_info'
        },
        {
          label: '港区', name: 'port', type: 'select', options: []
        },
        {
          label: '费用名称', name: 'fee_id', type: 'select', options: []
        },
        {
          label: '固定金额', name: 'amount', type: 'default'
        },
        {
          label: '客户名称', name: 'customer', type: 'select', func: getCustomer, params: { department: 7, customerName: '' }
        }
      ],
      moduleName: 'cums_fix_fee'
    }
  },
  created() {
    getCumsMetaOption('cums_meta_info', { type: 8 }).then(v => {
      this.formFields[0]['options'] = v
      this.formFields[1]['options'] = v
    })
    getCumsMetaOption('cums_meta_info', { type: 1 }).then(v => {
      this.formFields[3]['options'] = v
    })
    getCommonOptions('fee').then(v => {
      this.queryFields[0]['options'] = v
      this.formFields[4]['options'] = v
    })
  },
  methods: {
    getCumsMetaPage,
    createCumsMetaInfo,
    updateCumsMetaInfo,
    deleteCumsMetaInfo
  }
}
</script>
